import React from 'react'

export default function Intro() {
    return (
        <div className="wrap py-16">
            <div className="img-wrap">
                <img src="/ichi.jpeg" className='w-[100px] mx-auto' alt="ICHIGO" />
            </div>

            <div className="socials flex gap-5 justify-center items-center font-light uppercase text-lg mt-5">
                <a href="#" className='underline'>Socials:</a>
                <a href="https://t.me/Ichigo_Doge">Telegram</a>
                <a href="https://X.com/Ichigo_Doge">X </a>
              
            </div>

            <div className="wrap mt-16 text-center p-5">
                <p className='uppercase text-xl'>ICHIGO </p>
                <h1 className='lg:text-8xl text-4xl font-extrabold my-5 mt-3 font-jumb'>MEet $ICHIGO</h1>
                <span className='italic text-3xl'>🐕Kabosu, the Shiba Inu known as Doge, has a Blood sister named ICHIGO. Both were rescued from a puppy mill and adopted by different families. Ichigo has also been featured in photos, but she didn't achieve the same level of internet fame as Kabosu.
 </span>
            </div>

            <div className="btn-wrap text-center my-10 ">
            <button href ="" className='p-4 px-7 border border-black font-jumb text-red-800'>0x0f354476077ecac7f521c0621cc7e29e7dc2ef57</button>
            </div>

            <div className="btn-wrap text-center my-10 ">
            <a href ="https://www.dextools.io/app/en/ether/pair-explorer/0x0f354476077ecac7f521c0621cc7e29e7dc2ef57" className='p-4 px-7 border border-black font-jumb text-red-800'>DexTools</a>
            </div>

            <div className="btn-wrap text-center my-10 ">
            <a href ="https://www.dextools.io/app/en/ether/pair-explorer/?t=1722444675108" className='p-4 px-7 border border-black font-jumb text-red-800'>🐕ICHIGO IG</a>
            </div>

            <div className="wrap py-5 my-5">
                <div className="flex flex-wrap gap-5 justify-center">
                    <img src="/ichi.jpeg" alt="ICHIGO" className='w-[400px]' />
                    <img src="/ton.jpeg" alt="ICHIGO" className='w-[400px]' />
                    <img src="/sl.jpeg" alt="ICHIGO" className='w-[400px]' />
                    <img src="/lay.jpeg" alt="ICHIGO" className='w-[400px]' />
                </div>
            </div>
        </div>
    )
}
