import React from 'react'

export default function Links() {
    return (
        <div className="wrap bg-black bg-opacity-20 py-16">
            <div className="container mx-auto">
                <div className="header">
                    <h1 className='text-4xl font-bold  text-amber-800'>Links</h1>
                </div>

                <div className="flex flex-wrap gap-10 justify-center md:w-1/2 mx-auto font-jumb mt-3">
                    <a href="https://t.me/Ichigo_Doge">Telegram</a>
                    <a href="https://X.com/Ichigo_Doge">X</a>
                    
                    <a href="https://www.dexscreener.com/ethereum/0x0f354476077ecac7f521c0621cc7e29e7dc2ef57">DexScreener</a>
                    <a href ="https://www.dextools.io/app/en/ether/pair-explorer/0x0f354476077ecac7f521c0621cc7e29e7dc2ef57" className='p-4 px-7 border border-black font-jumb text-red-800'>DexTools</a>
                    

                </div>
            </div>
        </div>
    )
}
